define('ember-parachute/query-params', ['exports', 'ember-parachute/-private/symbols', 'ember-parachute/-private/parachute-meta', 'ember-parachute/-private/state'], function (exports, _symbols, _parachuteMeta, _state) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var get = Ember.get,
      set = Ember.set,
      Mixin = Ember.Mixin,
      assign = Ember.assign,
      assert = Ember.assert,
      isEmpty = Ember.isEmpty,
      computed = Ember.computed,
      isPresent = Ember.isPresent,
      setProperties = Ember.setProperties,
      NAME_KEY = Ember.NAME_KEY;
  var keys = Object.keys;

  var QueryParams = function () {
    /**
     * @method constructor
     * @constructor
     * @public
     * @returns {QueryParams}
     */
    function QueryParams() {
      _classCallCheck(this, QueryParams);

      var queryParams = assign.apply(undefined, [{}].concat(Array.prototype.slice.call(arguments)));

      // Cleanup the queryParams object. Some keys can be passed
      // as undefined via extend to nullify a QP
      queryParams = keys(queryParams).reduce(function (qps, key) {
        if (isPresent(queryParams[key])) {
          qps[key] = queryParams[key];
        }
        return qps;
      }, {});

      this.queryParams = queryParams;
      this.Mixin = this._generateMixin();
    }

    /**
     * Extend this QueryParams instance with the passed query paramaters
     *
     * @method extend
     * @public
     * @returns {QueryParams}
     */


    _createClass(QueryParams, [{
      key: 'extend',
      value: function extend() {
        return new (Function.prototype.bind.apply(QueryParams, [null].concat([this.queryParams], Array.prototype.slice.call(arguments))))();
      }
    }, {
      key: '_generateMeta',
      value: function _generateMeta() {
        return new _parachuteMeta.default(this.queryParams);
      }
    }, {
      key: '_generateMixin',
      value: function _generateMixin() {
        var _this = this,
            _Mixin$create;

        var _generateMeta2 = this._generateMeta(),
            queryParams = _generateMeta2.queryParams,
            defaultValues = _generateMeta2.defaultValues,
            qpMapForController = _generateMeta2.qpMapForController;

        /** @type {Ember.Mixin} */
        var ControllerMixin = Mixin.create(defaultValues, (_Mixin$create = {}, _defineProperty(_Mixin$create, _symbols.HAS_PARACHUTE, true), _defineProperty(_Mixin$create, _symbols.PARACHUTE_META, computed(function () {
          return _this._generateMeta();
        }).readOnly()), _defineProperty(_Mixin$create, 'queryParams', qpMapForController), _defineProperty(_Mixin$create, 'allQueryParams', computed.apply(undefined, _toConsumableArray(keys(queryParams)).concat([function () {
          return QueryParams.queryParamsFor(this);
        }])).readOnly()), _defineProperty(_Mixin$create, 'queryParamsState', computed.apply(undefined, _toConsumableArray(keys(queryParams)).concat([_symbols.PARACHUTE_META + '.queryParamsArray.@each.defaultValue', function () {
          return QueryParams.stateFor(this);
        }])).readOnly()), _defineProperty(_Mixin$create, 'queryParamsDidChange', function queryParamsDidChange() {}), _defineProperty(_Mixin$create, 'setup', function setup() {}), _defineProperty(_Mixin$create, 'reset', function reset() {}), _defineProperty(_Mixin$create, 'resetQueryParams', function resetQueryParams() {
          var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

          QueryParams.resetParamsFor(this, params);
        }), _defineProperty(_Mixin$create, 'setDefaultQueryParamValue', function setDefaultQueryParamValue(key, defaultValue) {
          QueryParams.setDefaultValue(this, key, defaultValue);
        }), _Mixin$create));

        ControllerMixin[NAME_KEY] = 'Parachute';

        return ControllerMixin;
      }
    }], [{
      key: 'metaFor',
      value: function metaFor(controller) {
        assert('[ember-parachute] The controller \'' + controller + '\' is not set up with ember-parachute.', this.hasParachute(controller));
        return get(controller, _symbols.PARACHUTE_META);
      }
    }, {
      key: 'hasParachute',
      value: function hasParachute(controller) {
        return controller && get(controller, _symbols.HAS_PARACHUTE);
      }
    }, {
      key: 'lookupQueryParam',
      value: function lookupQueryParam(controller, urlKey) {
        var _metaFor = this.metaFor(controller),
            queryParamsArray = _metaFor.queryParamsArray;

        return queryParamsArray.findBy('as', urlKey);
      }
    }, {
      key: 'queryParamsFor',
      value: function queryParamsFor(controller) {
        var _metaFor2 = this.metaFor(controller),
            queryParamsArray = _metaFor2.queryParamsArray;

        return queryParamsArray.reduce(function (qps, qp) {
          qps[qp.key] = qp.value(controller);
          return qps;
        }, {}, undefined);
      }
    }, {
      key: 'stateFor',
      value: function stateFor(controller) {
        return (0, _state.default)(controller);
      }
    }, {
      key: 'resetParamsFor',
      value: function resetParamsFor(controller) {
        var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

        var _metaFor3 = this.metaFor(controller),
            queryParamsArray = _metaFor3.queryParamsArray;

        /** @type {object} */
        var defaults = queryParamsArray.reduce(function (defaults, qp) {
          if (isEmpty(params) || params.indexOf(qp.key) > -1) {
            defaults[qp.key] = qp.defaultValue;
          }
          return defaults;
        }, {}, undefined);

        setProperties(controller, defaults);
      }
    }, {
      key: 'setDefaultValue',
      value: function setDefaultValue(controller, param, defaultValue) {
        var _metaFor4 = this.metaFor(controller),
            queryParams = _metaFor4.queryParams;

        assert('[ember-parachute] The query parameter \'' + param + '\' does not exist.', queryParams[param]);
        set(queryParams[param], 'defaultValue', defaultValue);
      }
    }]);

    return QueryParams;
  }();

  exports.default = QueryParams;
});