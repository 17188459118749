define('ember-parachute/-private/parachute-meta', ['exports', 'ember-parachute/-private/query-param'], function (exports, _queryParam) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var emberArray = Ember.A;
  var keys = Object.keys;

  var ParachuteMeta =
  /**
   * Creates an instance of ParachuteMeta.
   *
   * @param {Object} [queryParams={}]
   *
   * @memberof ParachuteMeta
   */
  function ParachuteMeta() {
    var _this = this;

    var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, ParachuteMeta);

    /** @type {object} */
    this.queryParams = keys(queryParams).reduce(function (qps, key) {
      qps[key] = new _queryParam.default(key, queryParams[key]);
      return qps;
    }, {});

    /** @type {Ember.NativeArray} */
    this.queryParamsArray = emberArray(keys(this.queryParams).map(function (key) {
      return _this.queryParams[key];
    }));

    /** @type {object} */
    this.qpMapForController = this.queryParamsArray.reduce(function (qps, _ref) {
      var key = _ref.key,
          as = _ref.as,
          scope = _ref.scope;

      qps[key] = { as: as, scope: scope };
      return qps;
    }, {});

    /** @type {object} */
    this.qpMapForRoute = this.queryParamsArray.reduce(function (qps, _ref2) {
      var key = _ref2.key,
          replace = _ref2.replace;

      qps[key] = { replace: replace };
      return qps;
    }, {});

    /** @type {object} */
    this.defaultValues = this.queryParamsArray.reduce(function (defaults, _ref3) {
      var key = _ref3.key,
          defaultValue = _ref3.defaultValue;

      defaults[key] = defaultValue;
      return defaults;
    }, {});
  };

  exports.default = ParachuteMeta;
});