define('ember-parachute/-private/query-param', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var get = Ember.get,
      assert = Ember.assert,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      canInvoke = Ember.canInvoke;
  var keys = Object.keys;


  var REQUIRED_PROPS = ['defaultValue'];

  /**
   * Normalized query param object.
   *
   * @export
   * @class QueryParam
   */

  var QueryParam = function () {
    function QueryParam(key) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      _classCallCheck(this, QueryParam);

      assert('[ember-parachute] You must specify a key to the QueryParam Class', isPresent(key));
      assert('[ember-parachute] You must specify all required fields for the query param: \'' + key + '\'', this._validateOptions(options));

      /** @type {string} */
      this.key = key;

      /** @type {string} */
      this.as = options.as || key;

      /** @type {"controller" | undefined} */
      this.scope = options.scope;

      /** @type {any} */
      this.defaultValue = options.defaultValue;

      /** @type {boolean} */
      this.refresh = Boolean(options.refresh);

      /** @type {boolean} */
      this.replace = Boolean(options.replace);

      /** @type {function(any): any} */
      this.serialize = options.serialize;

      /** @type {function(any): any} */
      this.deserialize = options.deserialize;
    }

    /**
     * Current query param value.
     *
     * @param {Ember.Controller} controller
     * @returns {any}
     *
     * @memberof QueryParam
     */


    _createClass(QueryParam, [{
      key: 'value',
      value: function value(controller) {
        return get(controller, this.key);
      }
    }, {
      key: 'serializedValue',
      value: function serializedValue(controller) {
        var value = this.value(controller);
        return canInvoke(this, 'serialize') ? this.serialize(value, controller) : value;
      }
    }, {
      key: 'toString',
      value: function toString() {
        return 'QueryParam<' + this.key + '>';
      }
    }, {
      key: '_validateOptions',
      value: function _validateOptions(options) {
        var optionKeys = keys(options);
        return !isEmpty(optionKeys) && REQUIRED_PROPS.every(function (p) {
          return optionKeys.indexOf(p) > -1;
        });
      }
    }]);

    return QueryParam;
  }();

  exports.default = QueryParam;
});