define('ember-parachute/-private/parachute-event', ['exports', 'ember-parachute/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var canInvoke = Ember.canInvoke,
      emberArray = Ember.A;
  var keys = Object.keys;

  var ParachuteEvent =
  /**
   * Creates an instance of ParachuteEvent.
   *
   * @param {string} routeName
   * @param {Ember.Controller} controller
   * @param {object} [changed={}]
   *
   * @memberof ParachuteEvent
   */
  function ParachuteEvent(routeName, controller) {
    var changed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    _classCallCheck(this, ParachuteEvent);

    var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
        queryParams = _QueryParams$metaFor.queryParams,
        queryParamsArray = _QueryParams$metaFor.queryParamsArray;

    var state = _queryParams.default.stateFor(controller);
    var changedKeys = emberArray(keys(changed));

    /**
     * The route the event was fired from
     * @type {string}
     */
    this.routeName = routeName;

    /**
     * All query params that have changed from this update event
     * @type {object}
     */
    this.changed = queryParamsArray.reduce(function (changedParams, qp) {
      if (changedKeys.includes(qp.as)) {
        changedParams[qp.key] = canInvoke(qp, 'deserialize') ? qp.deserialize(changed[qp.as], controller) : changed[qp.as];
      }
      return changedParams;
    }, {}, undefined);

    /**
     * All Query Params at this given moment
     * @type {object}
     */
    this.queryParams = _queryParams.default.queryParamsFor(controller);

    /**
     * Whether or not a model refresh should occur
     * @type {boolean}
     */
    this.shouldRefresh = emberArray(keys(this.changed)).any(function (key) {
      return queryParams[key].refresh;
    });

    /**
     * All query params that are not their default
     * @type {object}
     */
    this.changes = keys(state).reduce(function (changes, key) {
      if (state[key].changed) {
        changes[key] = state[key].value;
      }
      return changes;
    }, {});
  };

  exports.default = ParachuteEvent;
});