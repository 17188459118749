define('ember-parachute/-private/state', ['exports', 'ember-parachute/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = queryParamsStateFor;
  var assert = Ember.assert,
      isPresent = Ember.isPresent;


  /**
   * Creates QueryParamsState interface.
   *
   * @param {Ember.NativeArray} queryParamsArray
   * @param {Ember.Controller} controller
   * @returns {object}
   */
  function queryParamsState(queryParamsArray, controller) {
    return queryParamsArray.reduce(function (state, qp) {
      var value = qp.value(controller);

      state[qp.key] = {
        value: value,
        serializedValue: qp.serializedValue(controller),
        as: qp.as,
        defaultValue: qp.defaultValue,
        changed: JSON.stringify(value) !== JSON.stringify(qp.defaultValue)
      };
      return state;
    }, {}, undefined);
  }

  /**
   * Creates new instance of QueryParamsState for a given controller.
   *
   * @export
   * @public
   * @param {Ember.Controller} controller
   * @returns {object}
   */
  function queryParamsStateFor(controller) {
    assert('[ember-parachute] Cannot construct query params state object without a controller', isPresent(controller));

    var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
        queryParamsArray = _QueryParams$metaFor.queryParamsArray;

    return queryParamsState(queryParamsArray, controller);
  }
});